<template>
  <div  class="wraperBox"  v-wechat-title="this.goodsinfo.name+ '-药百万'">
    <el-row>
      <div class="wraper">
        <ShopHead :productName="productName"></ShopHead>
        <ShopGalss class="glass"  v-if="renderFlag"  :goodsinfo="goodsinfo.productVO"></ShopGalss>
        <ComboGoodsInfo
          class="info"
          v-if="renderFlag"
          :goodsinfo="goodsinfo"
        ></ComboGoodsInfo>
      </div>
    </el-row>
    <el-row>
      <!-- <el-col :span="24"  v-if="AssociatedFlag">
        <AssociatedDrug
          class="AssociatedDrug"
          v-if="renderFlag"
          :goodsinfo="goodsinfo.productVO"
          @ShowFun="AssociatedFun"
        ></AssociatedDrug>
      </el-col> -->
      <el-col :span="24">
        <DetailInfo
          class="detailInfo"
          v-if="renderFlag"
          :goodsinfo="goodsinfo.productVO"
        ></DetailInfo>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { selectABDetail } from "api/activity.js";
// import Qs from 'qs'
const ShopGalss = () => import("components/product/ShopGalss.vue");
const ComboGoodsInfo = () => import("components/product/ComboGoodsInfo.vue");
const ShopHead = () => import("components/product/ShopHead.vue");
const DetailInfo = () => import("components/product/DetailInfo.vue");
// const AssociatedDrug = () => import("components/product/AssociatedDrug.vue");
export default {
  name: "ProductDetail",
   inject:['reload'],
  data() {
    return {
      productName:'',
      renderFlag: false,
      // AssociatedFlag:true,
      goodsinfo: "",
      parameterValues: "",
    };
  },
  components: {
    ShopGalss,
    ComboGoodsInfo,
    ShopHead,
    DetailInfo,
    // AssociatedDrug,
  },
   watch: {
    //   监听路径变化，获取数据并给商品数据赋值
    "$route.query.productId": function() {
        this.reload();
    },
  },
  created() {
    this.getProductDetail();
  },
  methods: {
    // AssociatedFun(){
    //   this.AssociatedFlag=false
    // },
    getProductDetail() {
      const parmes = this.$route.query;
       // 显示loading
     this.$loading({ fullscreen: true ,background:'#ffffff45'})

    //  console.log(parmes)
      selectABDetail(parmes).then((res) => {
        //console.log(res.data.data);
        if(res.data.code==400){
          this.$message.error(res.data.msg);
          let that=this
          setTimeout(function(){that.$router.push({name:"Home"})},500);
        }
        this.productName=res.data.data.name
        this.goodsinfo = res.data.data;
        this.renderFlag = true;
        this.AssociatedFlag=true;
        this.parameterValues = res.data.data;
         // 关闭loading
          this.$loading().close();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.wraperBox{
   width: 1200px;
   margin:0px auto;
}
.el_main {
  background: #fff;
}
.wraper {
  margin-top: 20px;
  .glass {
    float: left;
  }
  .info {
    float: left;
    margin-left: 20px;
    width: calc(100% - 458px);
  }
  .detailInfo {
    width: 1200px;
  }
}
</style>

